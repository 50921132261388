import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { backendUrl } from '../../utils/constants/url'
import { getTokenFromLocalStorage } from '../../utils/functions/getToken'
import { type IBanksResponse } from '../types/BanksTypes'

export const BanksApi = createApi({
  reducerPath: 'Banks',
  tagTypes: ['Banks'],
  baseQuery: fetchBaseQuery({
    baseUrl: backendUrl,
    headers: { Authorization: `Token ${getTokenFromLocalStorage()}` },
  }),
  endpoints: (build) => ({
    getBanks: build.query<IBanksResponse, string | void>({
      query: (status = '') => ({
        params: { status },
        url: '/bank/',
        method: 'GET',
      }),
      providesTags: ['Banks'],
    }),
  }),
})

export const { useGetBanksQuery } = BanksApi
