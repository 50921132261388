import React from 'react'
import styles from './Navbar.module.css'
import { Link, useLocation } from 'react-router-dom'
import { APPLICATIONS, AUTH, CARDS, PAYMENTS, SETTINGS, WITHDRAWAL } from '../../utils/constants/routes'
import { useVisible } from '../../utils/hooks/useVisible'

export const Navbar = () => {
  const location = useLocation()
  const { notVisible } = useVisible()
  if (notVisible) return null
  return (
    <div className={styles.navBar}>
      <div className={styles.logo}>
        {' '}
        <img
          className={styles.logoImg}
          src={'/logo_paytrovka.png'}
          alt={'xproject_logo_navbar'}
        />
      </div>
      <Link to={CARDS} className='link-style'>
        <div className={location.pathname === CARDS ? styles.activeButton : styles.button}>
          <img
            src={location.pathname === CARDS ? '/rekvizits.svg' : '/rekvizits.svg'}
            alt={'cards_icon'}
          />{' '}
          Реквизиты
        </div>
      </Link>
      <Link to={PAYMENTS} className='link-style'>
        <div className={location.pathname === PAYMENTS ? styles.activeButton : styles.button}>
          <img
            src={
              location.pathname === PAYMENTS
                ? '/mywallet.svg'
                : '/mywallet.svg'
            }
            alt={'replenishment_icon'}
          />{' '}
          Мой кошелёк
        </div>
      </Link>
      <Link to={APPLICATIONS} className='link-style'>
        <div className={location.pathname === APPLICATIONS ? styles.activeButton : styles.button}>
          <img
            src={
              location.pathname === APPLICATIONS
                ? '/orders.svg'
                : '/orders.svg'
            }
            alt={'application'}
          />{' '}
          Заявки
        </div>
      </Link>
      <Link to={WITHDRAWAL} className='link-style'>
        <div className={location.pathname === WITHDRAWAL ? styles.activeButton : styles.button}>
          <img
            src={
              location.pathname === WITHDRAWAL
                ? '/viplaty.svg'
                : '/viplaty.svg'
            }
            alt={'settings_icon'}
          />{' '}
          Выплаты
        </div>
      </Link>
      <Link to={SETTINGS} className='link-style'>
        <div className={location.pathname === SETTINGS ? styles.activeButton : styles.button}>
          <img
            src={
              location.pathname === SETTINGS ? '/nastroyki.svg' : '/nastroyki.svg'
            }
            alt={'settings_icon'}
          />{' '}
          Настройки
        </div>
      </Link>
      <Link to={AUTH} className='link-style'>
        <div
          className={styles.button}
          style={{ color: '#FE5B37' }}
          onClick={() => {
            sessionStorage.removeItem('token')
          }}
        >
          <img src={'/logout_icon.png'} alt={'logout_icon'} /> Выход
        </div>
      </Link>
    </div>
  )
}
