import React, { type ReactNode } from 'react'
import './MainContainer.css'
import { Header } from '../Header/Header'
import { Navbar } from '../Navbar/Navbar'
import { useRecoilState } from 'recoil'
import { defaultIsMobile } from '../../utils/states/isMobile.state'

interface TMainContainerProps {
  children: ReactNode
  needLogo?: boolean
  style?: React.CSSProperties
}

export const MainContainer = (props: TMainContainerProps) => {
  const { children, needLogo, style } = props
  const [version] = useRecoilState(defaultIsMobile)
  const isMobile = version.isMobile
  return (
    <div className={'container'}>
      {isMobile ? <Header /> : <Navbar />}
      <div
        style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative' }}
      >
        {!isMobile && needLogo ? (
          <div className={'container_logo'}>
            <img src={'/logo_paytrovka.png'} alt={'container logo'} />
          </div>
        ) : null}
        <div className={'wrapper'} style={style}>
          {children}
        </div>
      </div>
    </div>
  )
}
