import './Pagination.css'
import React from 'react'
interface IPaginationProps {
  totalPages: number
  currentPage: number
  setCurrentPage: (page: number) => void
  nextPage: number
  previousPage: number
}

export const Pagination = (props: IPaginationProps)=> {
  const { totalPages, currentPage, previousPage, nextPage, setCurrentPage } = props
  const pages: number[] = []
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i)
  }
  const handleSwitchPage = (page: number) => {
    setCurrentPage(page)
    window.scrollTo(0,0)
  }
  const getPages = () : number[] => {
    if (pages.length <= 5) {
      return [...pages]
    }
    else {
      if (currentPage <= 4) {
        return [...pages.slice(0,4), 0, pages[pages.length -1]]
      }
      if (currentPage > 4 && currentPage <= pages.length - 4) {
        return [pages[0], 0, ...pages.slice(currentPage-1, currentPage + 3), 0, pages[pages.length-1]]
      }
      if (currentPage > pages.length - 5) {
        return [pages[0], 0, ...pages.slice(pages.length - 4, pages.length)]
      }
    }
    return [...pages]
  }
  if (totalPages < 2) {
    return null
  }
  return (
    <div className={'pagination'}>
      <img
        className={'page_link switch left'}
        src={'/left_arrow_pag.svg'}
        alt={'arrow left button icon'}
        onClick={() => {
          handleSwitchPage(previousPage)
        }}
      />

      {getPages().map((number, index) => {
        if (number === 0) {
          return (
            <input className={'page_link not_filled'} type={'button'} value={'...'} key={index} />
          )
        }
        return (
          <input
            key={index}
            className={currentPage === number ? 'page_link active' : 'page_link'}
            type={'button'}
            value={number}
            onClick={() => {
              handleSwitchPage(number)
            }}
          />
        )
      })}

      <img
        className={'page_link switch right'}
        src={'/right_arrow_pag.svg'}
        alt={'arrow right button icon'}
        onClick={() => {
          handleSwitchPage(nextPage)
        }}
      />
    </div>
  )
}
