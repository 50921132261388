import { type thModel } from '../types'

export const ordersThArray = [
  { name: 'UID', style: { width: '18.5%' } },
  { name: 'Статус', style: { width: '15.5%' } },
  { name: 'Реквизиты', style: { width: '16.5%' } },
  { name: 'Банк', style: { width: '12.5%' } },
  { name: 'Ожидается', style: { width: '12.5%' } },
  { name: 'Пришло', style: { width: '12.5%' } },
  { name: 'Дата', style: { width: '10.5%' } },
]

export const withdrawalsThArray = [
  { name: 'UID', style: { width: '18.5%' } },
  { name: 'Статус', style: { width: '15.5%' } },
  { name: 'Реквизиты', style: { width: '16.5%' } },
  { name: 'Банк', style: { width: '12.5%' } },
  { name: 'Ожидается', style: { width: '12.5%' } },
  { name: 'Дата', style: { width: '10.5%' } },
]
export const overflowThArray: thModel[] = [
  { name: 'Хэш транзакции', style: { width: '40%', textAlign: 'center' } },
  { name: 'Статус', style: { width: '20%', textAlign: 'center' } },
  { name: 'Сумма (USDT)', style: { width: '30%', textAlign: 'center' } },
  { name: 'Дата создания', style: { width: '30%', textAlign: 'center' } },
]
export const cardThArray: thModel[] = [
  { name: 'ID', style: { width: '4.5%' } },
  { name: 'Активность', style: { width: '8.5%' } },
  { name: 'Описание', style: { width: '12%' } },
  { name: 'Номер карты', style: { width: '15%' } },
  { name: 'ФИО', style: { width: '16.5%' } },
  { name: 'Банк', style: { width: '14%' } },
  { name: 'Общий лимит', style: { width: '9%' } },
  { name: 'Всего пополнений', style: { width: '9%' } },
  { name: 'Действие', style: { width: '9%' } },
]
export const settingsThArray: thModel[] = [
  { style: { width: '25%' }, name: 'Имя устройства' },
  { style: { width: '25%' }, name: 'ID Устройства' },
  { style: { width: '16%' }, name: 'Версия приложения' },
  { style: { width: '10%' }, name: 'Статус' },
  { style: { width: '24%' }, name: 'Действия' },
]
