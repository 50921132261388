import React from 'react'
import './Cards.css'
import { CardsSwitcher } from '../components/CardsSwitcher/CardsSwitcher'
import { AddCard } from '../components/AddCard/AddCard'
import { CardsContent } from '../components/Content/CardsContent'
import { MainContainer } from '../../../../components/MainContainer/MainContainer'

export const Cards = () => {
  return (
    <MainContainer>
      <div className={'cards'} style={{ height: '100%', position: 'relative' }}>
        <h3 className={'card_title'}> Карты</h3>
        <AddCard />
        <h5 className={'card_active'}>Активность карт</h5>
        <CardsSwitcher />
        <CardsContent />
      </div>
    </MainContainer>
  )
}
