import React from 'react'
import './ChangeDescriptionInput.css'
import { ControlledInput } from '../../../../../../../../components/ControlledInput/ControlledInput'

export interface TEditCardProps {
  id: number | null
  currentId: number | null
  defaultValue: string
}
export interface IInputsEditCard {
  newDescription: string
  newLimit: string
}
export const ChangeDescriptionInput = (props: TEditCardProps) => {
  const { id, currentId, defaultValue } = props
  return (
    <>
      {id !== null && currentId !== null && id === currentId ? (
        <ControlledInput
          inputStyle={{
            padding: '4px 6px',
            color: '#2c2e35',
            border: '1px solid #1890ff',
            borderRadius: '2px',
          }}
          placeholder={''}
          name={'newDescription'}
          type={'text'}
          defaultValue={defaultValue}
        />
      ) : (
        defaultValue
      )}
    </>
  )
}
