import React, { useRef, useState } from 'react'
import styles from './Selector.module.css'
import { useOnClickOutside } from '../../../../../utils/hooks/useOnClickOutside'

interface TOrdersSelectorProps {
  id: string
  statusId: string
  status: string
  handleEdit : (newStatus: string, id: string) => void
  needRecalculation?: boolean
  open : null | string
  setOpen : (value : null | string) => void
}

export const Selector = (props: TOrdersSelectorProps) => {
  const {
    id,
    status,
    statusId,
    handleEdit,
    needRecalculation = false,
    setOpen,
    open } = props
  const handleClose = () => setOpen(null)
  const handleSwitch = () => setOpen(open ? null : id)
  const selectorRef = useRef(null)
  useOnClickOutside(selectorRef, handleClose)
  if (statusId !== 'BN' && statusId !== 'ID') {
    return (
      <div className={styles.wrapper}>
        <div className={`${styles.head} ${styles.none}`}>
          <span>{status}</span>
        </div>
      </div>
    )
  }
  return (
    <div
      className={styles.wrapper}
      onClick={handleSwitch}
    >
      <div className={`${styles.head} ${styles.select_active}`}>
        {status}
        <img
          src={open === id ? '/arrow_up_icon.svg' : '/arrow_down_icon.svg'}
          alt={'arrow down icon'}
        />
      </div>
      {open === id && (
          <ul ref={selectorRef}
              id={id}>
            <li
                onClick={() => {
                  if (statusId === 'ID') {
                    handleEdit('FR', id);
                    handleClose();
                  } else {
                    handleEdit('SF', id);
                    handleClose();
                  }
                }}
            >
              Успешно завершенные
            </li>
            <li
                onClick={() => {
                  handleEdit('CR', id);
                  handleClose();
                }}
            >
              Отказ
            </li>
            {needRecalculation && statusId !== 'ID' &&
                <li
                    onClick={async () => {
                      handleEdit('IP', id);
                      handleClose();
                    }}
                >
                    Заявка с перерасчетом
                </li>
            }
          </ul>
      )}
    </div>
  )
}
