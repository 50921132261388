import React, { useState } from 'react'
import { type SubmitHandler, useForm, FormProvider } from 'react-hook-form'
import { useAuthMutation } from '../../../redux/services/AuthApi'
import { Loading } from '../../../components/Loading/Loading'
import styles from './Auth.module.css'
import { Button } from '../../../components/Button/Button'
import { ModalDefault } from '../../../components/ModalDefault/ModalDefault'
import { MANAGER_BOT } from '../../../utils/constants/text'
import { useNavigate } from 'react-router-dom'
import MainContainerWithWindow from '../../../components/MainContainerWithWindow/MainContainerWithWindow'
import { APPLICATIONS } from '../../../utils/constants/routes'
import { BaseInput } from '../../../components/BaseInput/BaseInput'

interface TInputs {
  username: string
  password: string
}

export const Auth = () => {
  const formMethods = useForm<TInputs>({
    mode: 'onSubmit',
    shouldUnregister: false,
  })
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods
  const navigate = useNavigate()
  const [authRequest, { isLoading }] = useAuthMutation()
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const [modalActive, setModalActive] = useState<boolean>(false)
  const handlePasswordVisible = () => {
    setPasswordVisible((prevState) => {
      return !prevState
    })
  }
  const onSubmit: SubmitHandler<TInputs> = async (data) => {
    await authRequest(data)
      .unwrap()
      .then((response) => {
        const token = response.auth_token
        sessionStorage.setItem('token', token)
        navigate(APPLICATIONS)
        window.location.reload()
      })
      .catch(() => {
        setError('root', { type: 'custom', message: 'credentials does`not exist' })
      })
  }
  if (isLoading) {
    return <Loading />
  }
  return (
    <FormProvider {...formMethods}>
      <MainContainerWithWindow>
        <div className={styles.wrapper}>
          <center>
            <img src={'/logo_paytrovka.png'} alt={'logo'} className={styles.logo} />
          </center>
          <h1 style={{color: 'white'}}>Вход</h1>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <BaseInput
              title={''}
              placeholder={'Логин'}
              rules={{ required: true }}
              name={'username'}
              type={'text'}
              imgLeft={errors.username || errors.root ? '/user_icon_red.png' : '/user_icon.png'}
              imgLeftWidth={24}
            />
            {errors.username && <p>Введите имя пользователя</p>}
            <BaseInput
              imgLeft={
                errors.password || errors.root ? '/password_icon_red.png' : '/password_icon.png'
              }
              imgLeftWidth={24}
              imgRight={passwordVisible ? '/eye_icon_active.png' : '/eye_icon.png'}
              imgRightAction={handlePasswordVisible}
              title={''}
              placeholder={'Пароль'}
              rules={{ required: true }}
              name={'password'}
              type={passwordVisible ? 'text' : 'password'}
              blockStyle={{ marginTop: '12px' }}
              imgRightWidth={18}
            />
            {errors.password && <p>Введите пароль</p>}
            {errors.root && <p>Неверный логин или пароль</p>}
            <Button
              text={'Вход'}
              type={'submit'}
              disabled={false}
              style={{ width: '100%', marginTop: '24px', marginBottom: '16px' }}
            />
          </form>
          <center>
            <span
              onClick={() => {
                setModalActive(true)
              }}
            >
              Забыли пароль?
            </span>
          </center>
          <ModalDefault
            title={'Забыли пароль?'}
            titleMargin={'8px'}
            active={modalActive}
            setActive={setModalActive}
            text={'Обратитесь к администратору для запроса пароля:'}
            textMargin={'8px'}
          >
            <a href={MANAGER_BOT} target='_blank' className={styles.email} rel='noreferrer'>
              <img src='/telegram-icon.png' alt={'email'} />
              @HELP_HUNTERPAY
            </a>
          </ModalDefault>
        </div>
      </MainContainerWithWindow>
    </FormProvider>
  )
}
