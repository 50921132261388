import React, { useEffect, useState } from 'react'
import { SwitchButton } from '../components/SwitchButton/SwitchButton'
import { ChangeDescriptionInput } from '../components/ChangeDescriptionInput/ChangeDescriptionInput'
import { ChangeLimitInput } from '../components/ChangeLimitInput/ChangeLimitInput'
import { ActionButtons } from '../components/ActionButtons/ActionButtons'
import { type CardsModel } from '../../../../../../../utils/types'
import { useGetCardsQuery } from '../../../../../../../redux/services/CardsApi'
import mapCards from '../../../../../../../utils/functions/map/mapCards'
import calculateElements from '../../../../../../../utils/functions/calcucateElements'
import { Loading } from '../../../../../../../components/Loading/Loading'
import { cardThArray } from '../../../../../../../utils/constants/th_arrays'
import { Table } from '../../../../../../../components/Table/Table'
import { Pagination } from '../../../../../../../components/Pagination/Pagination'
import { type TCardsContentProps } from '../../CardsContent'
export const CardsTable = (props: TCardsContentProps) => {
  const { currentId, setCurrentId } = props
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const { data, isLoading } = useGetCardsQuery({ page, page_size: pageSize })

  const handleResize = () => {
    setPageSize(calculateElements([450, 480, 510, 550, 570, 600, 650], 10))
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      <Table
        thArray={cardThArray}
        tdArray={
          mapCards(data?.results)?.map((card: CardsModel) => {
            console.log(card)
            return [
              { element: card.id, style: {} },
              { element: <SwitchButton active={card.active} id={card.id} />, style: {} },
              {
                element: (
                  <ChangeDescriptionInput
                    currentId={currentId}
                    id={card.id}
                    defaultValue={card.description}
                  />
                ),
                style: {},
              },
              { element: card.card_number, style: {} },
              { element: card.fio, style: { wordBreak: 'normal' } },
              { element: card.bank.name, style: {} },
              {
                element: (
                  <ChangeLimitInput
                    currentId={currentId}
                    id={card.id}
                    defaultValue={card.total_limit}
                  />
                ),
                style: {},
              },
              { element: card.total_income, style: {} },
              {
                element: (
                  <ActionButtons id={card.id} currentId={currentId} setCurrentId={setCurrentId} />
                ),
                style: {},
              },
            ]
          }) || []
        }
      >
        {' '}
      </Table>
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </>
  )
}
