import { type DeviceNameProps } from './types'
import styles from './DeviceName.module.css'

const DeviceName = (props: DeviceNameProps) => {
  const { name, model, date } = props
  return (
    <div className={styles.deviceInfo}>
      <p style={{color: 'white'}}>{name}</p>
      <p style={{color: 'white'}}>{model}</p>
      <span style={{color: '#DBE0F2'}}>{date}</span>
    </div>
  )
}

export default DeviceName
