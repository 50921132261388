import { ControlledInput } from '../../../../../../components/ControlledInput/ControlledInput'
import { Button } from '../../../../../../components/Button/Button'
import { ModalDefault } from '../../../../../../components/ModalDefault/ModalDefault'
import React, { useState } from 'react'
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form'
import { useCreateDeviceMutation } from '../../../../../../redux/services/DevicesApi'
import { Loading } from '../../../../../../components/Loading/Loading'
import { type CreateTokenModalProps } from './types'
import ViewToken from '../ViewToken/ViewToken'

interface TInputs {
  name: string
  linked_card_number: string
}
const CreateToken = (props: CreateTokenModalProps) => {
  const { active, setActive } = props
  const formMethods = useForm<TInputs>({
    mode: 'onChange',
    shouldUnregister: false,
  })
  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = formMethods
  const [activeView, setActiveView] = useState<boolean>(false)
  const [deviceId, setDeviceId] = useState<string>('')
  const [createToken, { isLoading }] = useCreateDeviceMutation()
  const onSubmit: SubmitHandler<TInputs> = async (data: TInputs) => {
    await createToken(data)
      .then((response) => {
        // @ts-expect-error
        setDeviceId(response.data.id)
        setActive(false)
        setActiveView(true)
        reset()
      })
      .catch((error) => {
        console.log(error)
      })
  }
  if (isLoading) {
    return <Loading />
  }
  return (
    <FormProvider {...formMethods}>
      <ModalDefault
        title={'Создание API-токена'}
        titleMargin={'24px'}
        active={active}
        setActive={setActive}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlledInput
            title={'Название токена'}
            rules={{ required: true, minLength: 1 }}
            blockStyle={{ marginBottom: '16px' }}
            placeholder={'Введите название'}
            name={'name'}
            type={'text'}
            required={true}
          />
          <ControlledInput
              title={'Номер карты'}
              placeholder={'XXXX XXXX XXXX XXXX'}
              name={'linked_card_number'}
              type={'text'}
              required={true}
              rules={{ required: true, maxLength: 19 }}
              blockStyle={{ marginBottom: '16px' }}
              transform={(text: string | undefined) => {
                if (text) {
                  const formattedText = text
                      .replace(/\D/g, '')
                      .replace(/(\d{4})/g, '$1 ')
                      .trim()
                      .slice(0, 19)
                  return formattedText
                }
                return text
              }}
              calculateNewPosition={(currentPosition : number, newPosition : number, valueLen : number) => {
                if (currentPosition === 5 || currentPosition === 10 || currentPosition === 15 ) {
                  return newPosition + 1
                }
                return newPosition
              }}
          />
          <Button text={'Создать'} disabled={!isValid} type={'submit'} />
        </form>
      </ModalDefault>
      <ViewToken deviceId={deviceId} active={activeView} setActive={setActiveView} />
    </FormProvider>
  )
}

export default CreateToken
