import { useRecoilState } from 'recoil'
import { defaultActiveOrdersFilter } from '../../../../../../../../utils/states/activeFilter.state'
import React, {useEffect, useState} from 'react'
import { useGetOrdersQuery, useUpdateOrderStatusMutation } from '../../../../../../../../redux/services/OrdersApi'
import calculateElements from '../../../../../../../../utils/functions/calcucateElements'
import { ordersThArray } from '../../../../../../../../utils/constants/th_arrays'
import mapOrders from '../../../../../../../../utils/functions/map/mapOrders'
import { Table } from '../../../../../../../../components/Table/Table'
import Income from '../../../../../components/Income/Income'
import { Selector } from '../../../../../components/Selector/Selector'
import { Pagination } from '../../../../../../../../components/Pagination/Pagination'
import { ModalDefault } from "../../../../../../../../components/ModalDefault/ModalDefault";
import {PureModal} from "../../PureModal";
import {pushNotify} from "../../../../../../../../components/Notifications";
import styles from "../../../../../components/Filter/Filter.module.css";

export const OrderTable = () => {
  const [{ activeOrdersFilter }] = useRecoilState(defaultActiveOrdersFilter);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(7);
  const [openSelector, setOpenSelector] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [orderUid, setOrderUid] = useState<string>('');
  const [searchId, setSearchId] = useState<string>()
  const { data, isSuccess } = useGetOrdersQuery(
      { status: activeOrdersFilter, page, page_size: pageSize, id: searchId?.trim() || '' },
      { pollingInterval: 5000 }
  );
  const { data: disputes, isSuccess: isDisputesSuccess } = useGetOrdersQuery(
      { status: 'ID', page: undefined, page_size: undefined },
      { pollingInterval: 5000 }
  );
  const [prevCount, setPrevCount] = useState<number | null | undefined>();
  const [updateOrder, { isLoading }] = useUpdateOrderStatusMutation();

  const handleEdit = async (newStatus: string, id: string) => {
    const body = { status: newStatus };
    await updateOrder({ body, id })
        .unwrap()
        .then(() => {});
  };

  const handleResize = () => {
    setPageSize(calculateElements([720, 760, 820, 870, 940, 990], 7));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setPage(1);
  }, [activeOrdersFilter]);

  useEffect(() => {
    if (disputes && prevCount === undefined) {
      setPrevCount(disputes.count);
    }
  }, [disputes, prevCount]);

  useEffect(() => {
    if (disputes && prevCount !== null && prevCount !== undefined) {
      if (disputes.count > prevCount) {
        pushNotify(`НОВЫЙ СПОР`, 'ВНИМАНИЕ, НОВЫЙ СПОР');
        setPrevCount(disputes.count);
      }
      setPrevCount(disputes.count)
    }
  }, [disputes]);

    return (
        <div>
          <div style={{width: '100%', display: 'flex', maxWidth: '440px'}}>
            <span style={{
              width: '70%',
              display: 'flex',
              marginBottom: '12px',
              alignItems: 'center',
              color: 'white'
            }}>Найдите заявку:</span>
            <input className={styles.input} type={'text'} value={searchId} onChange={(e) => setSearchId(e.target.value)}/>
          </div>
          <ModalDefault title={'Чек по операции'} active={openModal} setActive={setOpenModal} key={'suka'}
                        children={<PureModal orderUid={orderUid}/>}/>
          <Table
              thArray={ordersThArray}
              tdArray={
                  mapOrders(data?.results)?.map((order) => {
                    const style =
                        order.statusId == 'BN'
                            ? {background: 'rgba(47, 136, 60, 0.25)'}
                            : order.statusId === 'ID'
                                ? {background: 'rgba(246,86,86,0.41)'}
                                : {}
                    return [
                      {
                        element: order.statusId === 'ID' ?
                            <>
                              <a style={{cursor: 'pointer', color: '#1E82F7', textDecorationLine: 'underline'}}
                                 onClick={() => {
                                   setOpenModal((prevState) => !prevState)
                                   setOrderUid(order.id)
                                 }}>
                                {order.id}
                              </a>
                            </>
                            : order.id, style
                      },
                      {
                        element: <Selector
                            open={openSelector}
                            setOpen={setOpenSelector}
                            id={order.id}
                            status={order.status}
                            statusId={order.statusId}
                            handleEdit={handleEdit}
                            needRecalculation={true}
                        />,
                        style:
                            order.statusId == 'BN'
                                ? {background: 'rgba(47, 136, 60, 0.25)', padding: '0'}
                                : order.statusId === 'ID'
                                    ? {background: 'rgba(246,86,86,0.41)', padding: '0'}
                                    : {wordBreak: 'normal', padding: '0'},
                      },
                      {element: <div><div>{order.fio}</div> {order.card_number}</div>, style},
                      {element: order.bank, style},
                      {element: order.expected_income, style},
                      {element: order.statusId === 'ID'
                            ? <>{order.temp_income}<Income order={order}/></>
                            : <Income order={order}/>, style},
                      {
                        element: order.date,
                        style:
                            order.statusId == 'BN'
                                ? {background: 'rgba(47, 136, 60, 0.25)', wordBreak: 'normal'}
                                : order.statusId === 'ID'
                                    ? {background: 'rgba(246,86,86,0.41)', wordBreak: 'normal'}
                                    : {wordBreak: 'normal'},
                      },
                    ]
                  }) || []
              }
          > </Table>
          <Pagination
              totalPages={Math.ceil((data?.count || 0) / pageSize)}
              setCurrentPage={setPage}
              currentPage={page}
              nextPage={data?.next || page}
              previousPage={data?.previous || page}
          />
        </div>
    )
}
