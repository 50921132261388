import React, { useState } from 'react'
import { useAddCardMutation } from '../../../../../redux/services/CardsApi'
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form'
import { useGetBanksQuery } from '../../../../../redux/services/BanksApi'
import { Button } from '../../../../../components/Button/Button'
import { Loading } from '../../../../../components/Loading/Loading'
import { ModalDefault } from '../../../../../components/ModalDefault/ModalDefault'
import { ControlledInput } from '../../../../../components/ControlledInput/ControlledInput'
import { Selector, type TItem } from '../../../../../components/Selector/Selector'
import { parseStringToNumber } from '../../../../../utils/functions/parseNumber'
import { type TBanksModel } from '../../../../../redux/types/BanksTypes'

interface TInputs {
  description: string
  cardNumber: string
  bankName: string
  limit: string
  name: string
}

export const AddCard = () => {
  const formMethods = useForm<TInputs>({
    mode: 'onSubmit',
    shouldUnregister: false,
  })
  const { handleSubmit, setError, reset } = formMethods
  const [activeModal, setActiveModal] = useState<boolean>(false)
  const [cardExist, setCardExist] = useState<boolean>(false)
  const { data: banks, isLoading } = useGetBanksQuery()
  const [addCard] = useAddCardMutation()
  const onSubmit: SubmitHandler<TInputs> = async (data) => {
    if (!data.bankName) {
      setError('bankName', { type: 'manual', message: 'You need to select a bank' })
    } else {
      const newCard = {
        card_number: data.cardNumber,
        active: true,
        total_limit: parseStringToNumber(data.limit),
        fio: data.name,
        description: data.description,
        bank: Number(
          banks?.results.find((bank) => {
            if (data.bankName === bank.name) {
              return bank
            }
          })!.id,
        ),
      }
      await addCard(newCard)
        .unwrap()
        .then(() => {
          setActiveModal(false)
          reset()
        })
        .catch((error) => {
          if (error.status === 400) {
            setActiveModal(false)
            setCardExist(true)
          }
        })
    }
  }
  if (isLoading) {
    return <Loading />
  }
  return (
    <FormProvider {...formMethods}>
      <div>
        <Button
          text={'Добавить карту'}
          type={'button'}
          action={() => {
            setActiveModal(true)
          }}
          style={{ position: 'absolute', right: '0', top: '0' }}
        />
        <ModalDefault
          title={'Новая карта'}
          titleMargin={'24px'}
          active={activeModal}
          setActive={setActiveModal}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <ControlledInput
              title={'Описание'}
              placeholder={'Введите описание'}
              name={'description'}
              type={'text'}
              blockStyle={{ marginBottom: '16px' }}
            />
            <ControlledInput
              title={'Номер карты'}
              placeholder={'XXXX XXXX XXXX XXXX'}
              name={'cardNumber'}
              type={'text'}
              required={true}
              rules={{ required: true, maxLength: 19 }}
              blockStyle={{ marginBottom: '16px' }}
              transform={(text: string | undefined) => {
                if (text) {
                  const formattedText = text
                    .replace(/\D/g, '')
                    .replace(/(\d{4})/g, '$1 ')
                    .trim()
                    .slice(0, 19)
                  return formattedText
                }
                return text
              }}
              calculateNewPosition={(currentPosition : number, newPosition : number, valueLen : number) => {
                if (currentPosition === 5 || currentPosition === 10 || currentPosition === 15 ) {
                  return newPosition + 1
                }
                return newPosition
              }}
            />
            <Selector
              title={'Наименование банка'}
              name={'bankName'}
              placeholder={'Выберите наименование банка'}
              required={true}
              blockStyle={{ marginBottom: '16px' }}
              items={
                banks?.results.map((bank: TBanksModel): TItem => {
                  return {
                    name: bank.name,
                    id: String(bank.id),
                  }
                }) || []
              }
            />
            <ControlledInput
              title={'Общий лимит'}
              placeholder={'Лимит карты'}
              name={'limit'}
              type={'text'}
              required={true}
              rules={{ required: true, maxLength: 13 }}
              blockStyle={{ marginBottom: '16px' }}
              transform={(text: string | undefined) => {
                if (text) {
                  const formattedText = text
                    .replace(/[^0-9]/g, '')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
                    .slice(0, 13)
                  return formattedText
                }
                return text
              }}
              calculateNewPosition={(currentPosition : number, newPosition : number, valueLen : number, value) => {
                if (valueLen - 1 === currentPosition || valueLen === currentPosition) {
                  return valueLen
                }
                const extra = value.replaceAll(' ', '').length % 3
                const positions : number[] = [extra > 0 ? extra + 1 : 4]
                for (let i = 0; i < 4; i ++) {
                  positions.push(positions[i] + 4)
                }
                if (positions.includes(currentPosition)) {
                  return newPosition + 1
                }
                return newPosition
              }}
            />
            <ControlledInput
              title={'ФИО'}
              placeholder={'ФИО'}
              name={'name'}
              type={'text'}
              required={true}
              rules={{ required: true }}
              blockStyle={{ marginBottom: '24px' }}
              transform={(text: string | undefined) => {
                if (text) {
                  const formattedText = text.slice(0, 30)
                  return formattedText
                }
                return text
              }}
            />
            <Button text={'Добавить'} type={'submit'} disabled={false} />
          </form>
        </ModalDefault>

        <ModalDefault
          title={'Ошибка'}
          titleMargin={'8px'}
          textMargin={'24px'}
          active={cardExist}
          setActive={setCardExist}
          text={'Эта карта уже была добавлена. Хотите добавить новую?'}
        >
          <Button
            action={() => {
              setCardExist(false)
            }}
            text={'Отмена'}
            filled={false}
            style={{ marginRight: '8px' }}
          />
          <Button
            action={() => {
              setCardExist(false)
              setActiveModal(true)
            }}
            text={'Добавить новую'}
          />
        </ModalDefault>
      </div>
    </FormProvider>
  )
}
